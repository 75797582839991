.Container {
  margin: auto;
  width: 80%;
}

@media (min-width: 1000px) {
  .Container {
    width: 800px;
  }
}
