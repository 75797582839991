.Button {
  background-color: var(--submit-button-backgrouns);
  border: none;
  color: var(--submit-button-color);
  outline: none;
  cursor: pointer;
  font: inherit;
  padding: 10px;
  margin: 10px;
  font-weight: bold;
  border-radius: 5px;
}

.Button:disabled {
  color: var(--submit-button-disabled-color);
  cursor: not-allowed;
}

@media (max-width: 550px) {
  .Button {
    padding: 8px;
    margin: 8px;
    font-size: 0.8rem;
  }
}

@media (max-width: 370px) {
  .Button {
    padding: 6px;
    margin: 6px;
    font-size: 0.6rem;
  }
}
