@import "../../../../GlobalVariable.module.css";

.NavigationItem {
  margin: 10px 0;
  box-sizing: border-box;
  display: block;
  width: 100%;
  text-align: center;
}

.NavigationItem a {
  color: var(--sidebar-toolbar-text-color);
  text-decoration: none;
  width: 100%;
  box-sizing: border-box;
  display: block;
}

.NavigationItem a:hover,
.NavigationItem a:active,
.NavigationItem a.active {
  color: var(--sidebar-toolbar-text-color);
}

@media (min-width: 500px) {
  .NavigationItem {
    margin: 0;
    display: -webkit-box; /* OLD - iOS 6-, Safari 3.1-6, BB7 */
    display: -webkit-flex; /* NEW - Safari 6.1+. iOS 7.1+, BB10 */
    display: flex;
    height: 100%;
    width: auto;
    -webkit-align-items: center;
    align-items: center;
  }

  .NavigationItem a {
    height: 100%;
    padding: 16px 10px;
    border-bottom: 4px solid transparent;
  }

  .NavigationItem a:hover,
  .NavigationItem a:active,
  .NavigationItem a.active {
    border-bottom: 4px solid var(--sidebar-toolbar-text-color);
  }
}

@media (max-width: 499px) {
  .NavigationItem a:hover,
  .NavigationItem a:active,
  .NavigationItem a.active {
    text-decoration: underline;
  }
}
