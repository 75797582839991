.Container {
  margin: 20px auto;
  /* text-align: center;*/
  box-shadow: 0 2px 3px var(--shadow);
  border: 1px solid var(--border);
  padding: 10px;
  box-sizing: border-box;
  border-radius: 5px;
}
@media (min-width: 1000px) {
  .Container {
    width: 800px;
  }
}
