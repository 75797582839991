@import "../../GlobalVariable.module.css";

.Input {
  width: 100%;
  padding: 10px;
  box-sizing: border-box;
}

.InputElement {
  outline: none;
  border: 1px solid var(--border);
  background-color: var(--background);
  font: inherit;
  padding: 6px 10px;
  display: block;
  width: 100%;
  box-sizing: border-box;
  resize: vertical;
}

.InputElement:focus {
  outline: none;
  background-color: var(--element-focus-background);
}

.Invalid {
  border: 1px solid var(--invalid-border);
  background-color: var(--invalid-background);
}

@media (max-width: 550px) {
  .InputElement {
    font-size: 0.8rem;
  }
}
@media (max-width: 370px) {
  .InputElement {
    font-size: 0.6rem;
  }
}
