@import "../../../GlobalVariable.module.css";

.SideDrawer {
  position: fixed;
  width: 280px;
  max-width: 70%;
  height: 100%;
  left: 0;
  top: 0;
  z-index: var(--sidebar-z-index);
  background-color: var(--sidebar-toolbar-background-color);
  padding: 32px 16px;
  box-sizing: border-box;
  transition: transform 0.3s ease-out;

  display: -webkit-box; /* OLD - iOS 6-, Safari 3.1-6, BB7 */
  display: -webkit-flex; /* NEW - Safari 6.1+. iOS 7.1+, BB10 */
  display: flex;
  -webkit-flex-direction: column;
  -moz-flex-direction: column;
  flex-direction: column;
  -webkit-align-items: center;
  align-items: center;
}

.Open {
  transform: translateX(0);
}

.Close {
  transform: translateX(-100%);
}

.Logo {
  height: 11%;
  margin-bottom: 32px;
}
