.row {
  display: flex;
  flex-direction: row;
}

.left {
  float: left;
  margin-right: 60px;
}

.right {
  float: left;
}
