* {
  -webkit-tap-highlight-color: transparent;
}
:root {
  /*Primary colors*/
  --sidebar-toolbar-background-color: #4da7e3;
  --sidebar-toolbar-text-color: white;
  --drawer-toggle-color: white;
  --spinner-color: #4da7e3;
  --error-color: #ff2058;
  --error-background: white;
  --error-title-button-background: #4da7e3;
  --error-title-button-color: white;
  --shadow: #888888;
  --default-color: black;

  /*Toolbar, sidebar, menu*/
  --toolbar-height: 56px;

  /**Z-index*/
  --toolbar-z-index: 90;
  --sidebar-z-index: 150;
  --sidebar-backdrop-z-index: 100;
  --modal-backdrop-z-index: 200;
  --modal-window-z-index: 250;

  /*Backdrop*/
  --backdrop-color: 0, 0, 0;

  /*Input*/
  --element-focus-background: #ccc;
  --invalid-border: #ff2058;
  --invalid-background: #fda49a;
  --background: white;
  --submit-button-backgrouns: #4da7e3;
  --submit-button-disabled-color: #ccc;
  --submit-button-color: white;

  /*FloatingButton*/

  --fb-hint-bck: 51, 51, 51;
  --fb-hint-color: white;
  --fb-color: white;
  --floating-button: #4da7e3;
  --floating-shadow: #999;
  /***/
  --border: #ccc;
  --box-backgorund-hover: #e6f5ff;
}
/*
@media screen and (max-width: 500px) {
  :root {
    display: none;
  }
}
*/
