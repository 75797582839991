@import "../../GlobalVariable.module.css";

.Backdrop {
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: var(--sidebar-backdrop-z-index);
  left: 0;
  top: 0;
  background-color: rgba(var(--backdrop-color), 0.5);
}
