.NavigationItems {
  margin: 0;
  padding: 0;
  list-style: none;

  display: -webkit-box; /* OLD - iOS 6-, Safari 3.1-6, BB7 */
  display: -webkit-flex; /* NEW - Safari 6.1+. iOS 7.1+, BB10 */
  display: flex;
  -webkit-flex-direction: column;
  -moz-flex-direction: column;
  flex-direction: column;
  -webkit-align-items: center;
  align-items: center;
  height: 100%;
}

@media (min-width: 500px) {
  .NavigationItems {
    flex-flow: row;
  }
}
